import React, { useState } from "react";
import workPoster from "../Images/how-does-work.webp";

import conceptIcon from "../Images/conceptIcon.png";
import perfectionIcon from "../Images/perfectionIcon.png";
import designIcon from "../Images/designIcon.png";
import workIcon from "../Images/workIcon.png";
import mapPoster from "../Images/1BHK.webp";
import MapImg from "../Images/MapImg.png";
import MobilePoster from "../Images/Priority-Pass-Mobile.png";
import { AiOutlinePlus } from "react-icons/ai";
import { Accordion } from "react-bootstrap";
import Footer from "./Footer";
import USP1 from "../Images/Usps/1.webp";
import USP2 from "../Images/Usps/2.webp";
import USP3 from "../Images/Usps/3.webp";
import USP4 from "../Images/Usps/4.webp";
import USP5 from "../Images/Usps/5.webp";
import SliderHome from "./Sliders/SliderHome";
import Slider1 from "./Sliders/Slider1";
import Slider2 from "./Sliders/Slider2";
import Slider3 from "./Sliders/Slider3";
import FormModal from "./FormModal";

import unit1Img from "../Images/680BHK.webp";
import unit2Img from "../Images/580.webp";
import unit3Img from "../Images/630BHK.webp";
import unit4Img from "../Images/670BHK.webp";
import unit5Img from "../Images/750BHK.webp";
import unit6Img from "../Images/705BHK.webp";
import unit7Img from "../Images/720BHK.webp";
import EpicFormModal from "./EpicFormModal";
import Header from "./Header";
import BuilderSection from "./BuilderSection";

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);

  const [selectedImage, setSelectedImage] = useState(mapPoster);
  const [selectedUnit, setSelectedUnit] = useState("Floor Plan");
  const [epicModalShow, setEpicModalShow] = useState(false);

  const handleImageChange = (newImage, newUnit) => {
    setSelectedImage(newImage);
    setSelectedUnit(newUnit);
  };

  const handleModal = () => {
    // <FormModal modalShow={modalShow} setModalShow={setModalShow} />;
    setModalShow(true);
  };

  const handleEpicModal = () => {
    setEpicModalShow(true);
  };

  const imageUrls = [
    {
      url: mapPoster,
      unit: "Floor Plan",
    },
    {
      url: unit1Img,
      unit: "680 sqft",
    },
    {
      url: unit2Img,
      unit: "580 sqft",
    },
    {
      url: unit3Img,
      unit: "630 sqft",
    },
    {
      url: unit4Img,
      unit: "670 sqft",
    },
    {
      url: unit5Img,
      unit: "750 sqft",
    },
    {
      url: unit6Img,
      unit: "705 sqft",
    },
    {
      url: unit7Img,
      unit: "720 sqft",
    },
  ];
  const handleScroll = () => {
    document
      .getElementById("parallax-1")
      .scrollIntoView({ behavior: "smooth", alignToTop: true });
  };
  const handleScroll2 = () => {
    document
      .getElementById("parallax-2")
      .scrollIntoView({ behavior: "smooth", alignToTop: true });
  };
  const handleScroll3 = () => {
    document
      .getElementById("parallax-3")
      .scrollIntoView({ behavior: "smooth", alignToTop: true });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setModalShow(true);
    }, 10000);
  }, []);

  return (
    <>
      <Header />
      <div className="home">
        <SliderHome />
      </div>
      <div className="home-sec-2 my-5 col-11 col-lg-9">
        <h4 className="text-center">
          Embrace the Rare Opportunity to Earn 75k Monthly Rental Income in Residential
          Real Estate
        </h4>
        <p className="my-3 text-center">
          Codename EpicYield is not your ordinary residential real estate
          project. Forget the usual talk about luxury living, super prime
          location, lifestyle amenities, high rise, green and sustainable
          buildings, etc. We have all of these too, by the way! We're here to
          rewrite the rulebook and offer you something truly extraordinary:
          <b> rental income that defy expectations.</b>
        </p>
        <p className="my-3 text-center">
          While the average residential market struggles to reach 3-4%,
          <b>Codename EpicYield</b> stands apart, delivering{" "}
          <b>monthly rental income of up to 75k.</b>
        </p>
        <div
          className="btn-yellow my-3 modal-btn"
          onClick={() => handleModal()}
        >
          Enquire Now
        </div>
      </div>
      <div className="home-sec-3" id="overview-sec">
        <div className="home-sec-3-bg"></div>
        <div className="sec-3-1 ">
          <h2>1.2 Acres</h2>
          <p className="font-sm-size-sm">Land Area</p>
        </div>
        <div className="sec-3-1">
          <h2>1 BHK</h2>
          <p className="font-sm-size-sm">Configuration</p>
        </div>
        <div className="sec-3-1">
          <h2>84 Units</h2>
          <p className="font-sm-size-sm">No. of Units</p>
        </div>
        <div className="sec-3-1">
          <h2>550 - 700 </h2>
          <p className="font-sm-size-sm">Sqft</p>
        </div>
      </div>
      <div
        onScroll={handleScroll}
        id="parallax-1"
        className="home-sec-3-parallax pt-5 mb-3"
      >
        <div className="home-sec-3-poster ">
          <div className="poster-text">
            <h3 className="mb-0">How does it work?</h3>
          </div>
        </div>
        <div className="col-12 bg-col-white ">
          <div className="home-sec-4  py-5 col-12 flex-wrap flex-md-nowrap  col-md-11 col-lg-9">
            <div className="col-12 col-md-6 poster d-flex justify-content-center">
              <img src={workPoster} className="col-12 " alt="poster" />
            </div>
            <div className="col-12 col-md-6 explore">
              <Accordion className="p-0" defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="work">
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">
                        An Exclusive Tower Housing 84 State of the Art 1BHK
                        Apartments
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    We have an exclusive block/ tower which has 84 units of
                    luxury 1 BHK apartments having an area of 550 to 700 sqft.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="work">
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">
                        High-Rent Yielding Serviced Apartments/ Residences
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    This exclusive tower will be given on a lease or management
                    of operations model to companies that manage and operate
                    serviced apartments/ residences.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="work">
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">
                        Investment Options Starting from 85 Lakhs
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    You can now own these apartments starting at a price of 85
                    Lakhs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="work">
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">Earn Monthly Rentals of up to 75k</h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    Based on the apartment you select, you can get monthly
                    rental income of up to 75k.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="work">
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">
                        Finance Your Investment via Home Loans
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    Yes, that’s right. You can avail home loans from leading
                    banks and NBFCs to finance your investment.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="work">
                    {" "}
                    <div className="text-start d-flex align-items-center p-1">
                      <div className="me-3 icon">
                        <AiOutlinePlus size={22} />
                      </div>
                      <h6 className="mb-0">
                        Earn Monthly Rentals More Than Your Home Loan EMIs
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-start acc-body">
                    This is where it gets even more amazing. With rental income
                    of up to 10%, your rental income will pay for your EMIs and
                    help you earn even more.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                className="btn-yellow mt-4 px-5"
                onClick={() => handleModal()}
              >
                Click to know more
              </div>
            </div>
          </div>
        </div>
        <div className="bg-yellow-head align-items-center d-flex justify-content-center ">
          <h3 className="mb-0 text-center">Let's move over to some more Epic Stuff Now</h3>
        </div>
        <div className="home-sec-4-poster" id="epic-amenities-sec">
          <div className="poster-text">
            <h3 className="mb-0">Epic Amenities</h3>
          </div>
        </div>
        <div className="col-12 pt-3  pb-3 pb-sm-5 bg-col-white">
          <div className="home-sec-5  pb-3 col-10">
            <h4 className="my-4 text-center">
              Amenities So Magnificent, Your Every Moment Will Be Enriched
            </h4>
            <Slider1 className="col-12" />
          </div>
        </div>
      </div>
      <div className="home-sec-6-poster" id="highlights-sec">
        <div className="poster-text">
          <h3 className="mb-0">EPIC Highlights</h3>
        </div>
      </div>
      <div className="home-sec-6 my-2 my-md-5 col-11 col-xl-8 ">
        <div className=" d-flex justify-content-center align-items-center">
          <h4 className="my-5 text-center">
            A Project So Innovative, It Redefines the Norms
          </h4>
        </div>
        <div className="project-card my-2 my-md-5">
          <div className="col-6">
            <img className="image" src={USP1} alt="poster" />
          </div>
          <div className="col-6 ">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img className="icon" src={conceptIcon} alt="icon" />
              <h1>01</h1>
            </div>
            <h4 className="text-start">Prime Location</h4>
            <p className="text-start">
              Nestled in the heart of Marathahalli, East Bangalore, High Cliff
              offers an enviable address that brings convenience and
              accessibility right to your doorstep.{" "}
            </p>
          </div>
        </div>
        <div className="project-card my-2 my-md-5">
          <div className="col-6 ">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img className="icon" src={workIcon} alt="icon" />
              <h1>02</h1>
            </div>
            <h4 className="text-start">Unparalleled Luxury</h4>
            <p className="text-start">
              {" "}
              Experience the epitome of luxury living at High Cliff, where every
              detail is meticulously designed to provide a lavish and
              sophisticated lifestyle.
            </p>
          </div>
          <div className="col-6">
            <img className="image" src={USP2} alt="poster" />
          </div>
        </div>
        <div className="project-card my-2 my-md-5">
          <div className="col-6">
            <img className="image" src={USP3} alt="poster" />
          </div>
          <div className="col-6 ">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img className="icon" src={designIcon} alt="icon" />
              <h1>03</h1>
            </div>
            <h4 className="text-start">Elevated Living</h4>
            <p className="text-start">
              {" "}
              Rise above the ordinary with our high-rise residential towers,
              offering breathtaking panoramic views of the cityscape and a sense
              of exclusivity.
            </p>
          </div>
        </div>
        <div className="project-card my-2 my-md-5">
          <div className="col-6 ">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img className="icon" src={perfectionIcon} alt="icon" />
              <h1>04</h1>
            </div>
            <h4 className="text-start">Lifestyle Beyond Compare</h4>
            <p className="text-start">
              Indulge in a world of leisure and recreation with our thoughtfully
              curated amenities, including a fully-equipped business center, a
              rejuvenating spa, a state-of-the-art gymnasium, landscape gardens
              and much more.
            </p>
          </div>
          <div className="col-6">
            <img className="image" src={USP4} alt="poster" />
          </div>
        </div>
        <div className="project-card my-2 my-md-5">
          <div className="col-6">
            <img className="image" src={USP5} alt="poster" />
          </div>
          <div className="col-6 ">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img className="icon" src={conceptIcon} alt="icon" />
              <h1>05</h1>
            </div>
            <h4 className="text-start">Sustainable Living</h4>
            <p className="text-start">
              High Cliff is more than just a luxury residence; it's a commitment
              to a greener future. Embrace eco-friendly living in our green
              building, designed to minimize environmental impact without
              compromising on comfort and style.{" "}
            </p>
          </div>
        </div>
        <div className="btn-yellow  mx-auto my-5" onClick={() => handleModal()}>
          Book A Site Visit
        </div>
      </div>
      <div
        onScroll={handleScroll2}
        className="home-sec-7-parallax"
        id="parallax-2"
      >
        <div className="home-sec-7-poster">
          <div className="poster-text">
            <h3 className="mb-0">EPIC Space Planning</h3>
          </div>
        </div>
        <div className="col-12 bg-col-white">
          <div className="home-sec-7 col-11 col-xl-8">
            <div className="my-5 " id="floor-plans-sec">
              <h4 className="text-center">
                Space Planning So Efficient, Your Home Will Maximize
                Functionality.
              </h4>
              <div className="my-3 my-sm-5 house-types flex-wrap">
                {imageUrls.map((imageUrl, index) => (
                  <div className="col-3  m-auto">

                    <p
                      key={index}
                      className="col-11 col-sm-10 mx-auto text-center"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedUnit === imageUrl.unit ? "#f1ce3c" : "",
                      }}
                      onClick={() =>
                        handleImageChange(imageUrl.url, imageUrl.unit)
                      }
                    >
                      <b>{`${imageUrl.unit}`}</b>
                    </p>
                  </div>
                ))}
              </div>
              <img className="w-100" src={selectedImage} alt="poster" />
            </div>
          </div>
        </div>
        <div className="home-sec-8-poster" id="gallery-sec">
          <div className="poster-text">
            <h3 className="mb-0">EPIC Design</h3>
          </div>
        </div>
        <div className="col-12 mb-5 bg-col-white">
          <div className="home-sec-8 col-12">
            <div>
              <h4 className=" my-5 text-center">
                Design So Exquisite, Every Detail Will Inspire Awe
              </h4>
            </div>
            <Slider2 />
            <div className="btn-yellow my-5" onClick={() => handleModal()}>
              Enquire Now
            </div>
          </div>
        </div>
        <div className="home-sec-9-poster " id="location-sec">
          <div className="poster-text">
            <h3 className="mb-0">EPIC Location</h3>
          </div>
        </div>
        <div className="col-12 bg-col-white ">
          <div className="home-sec-9  col-11 col-xl-8">
            <h4 className="my-5 text-center">
              A Location So Prime, The Demand Will Always Outstrip Supply
            </h4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5249746854456!2d77.69921169999999!3d12.9382237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1252ab90e5b5%3A0x6f43ca9b0da3b95a!2sHigh%20Cliff%2C%20Panathur%20Main%20Rd%2C%20Munireddy%20Layout%2C%20Kadubeesanahalli%2C%20Panathur%2C%20Bengaluru%2C%20Karnataka%20560103!5e0!3m2!1sen!2sin!4v1692255637037!5m2!1sen!2sin"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="col-12 bg-col-white">
          <div className="home-sec-9  col-11 col-xl-10">
            <div className="row flex-wrap justify-content-center my-5">
              <div className=" yellow-box-hover mb-4 px-2">
                <h5 className="text-center">Tech Hubs</h5>
                <ul className="my-3 text-start">
                  <li>Oracle Tech Hub - 750 mtrs</li>
                  <li>Cessna Business Park
                    - 950 mtrs</li>
                  <li>Helios Business Park
                    - 1.5Kms</li>
                  <li>Embassy Tech Village
                    - 2.1Kms</li>
                  <li>Rmz Ecoworld - 3.2 km</li>
                  <li>Accenture Bengaluru Innovation Hub - 3.3 km</li>
                  <li>Hindustan Unilever Research Center - 8.5 km</li>
                </ul>
              </div>
              <div className=" yellow-box-hover mb-4 px-2">
                <h5 className="text-center">Shopping Malls</h5>
                <ul className="my-3 text-start">
                  <li>Centro Mall, Bellandur - 3.1 km</li>
                  <li>Brookefield Mall - 5.6 km</li>
                  <li>D Mart - 6.8 km</li>
                  <li>Nexus Mall, Whitefield - 8.4 km</li>
                  <li>Phoenix Marketcity - 10 km</li>
                </ul>
              </div>
              <div className=" yellow-box-hover mb-4 px-2">
                <h5 className="text-center">Schools</h5>
                <ul className="my-3 text-start">
                  <li>Vibgyor Group Of School - 220 mtrs</li>
                  <li>Orchids The International School - 260 mtrs</li>
                  <li>New Horizon Gurukul - 650 mtrs</li>
                  <li>Insight Academy School Bangalore - 850 mtrs</li>
                </ul>
              </div>
              <div className=" px-2 yellow-box-hover mb-4">
                <h5 className="text-center">Hospitals</h5>
                <ul className="my-3 text-start">
                  <li>Sakra World Hospital - 2.5 km</li>
                  <li>Sankara Eye Hospital - 5 km</li>
                  <li>
                    Apollo Medical Centre - 5.3
                  </li>
                  <li>Yashomati Hospital - 5.5 km</li>
                </ul>
              </div>
              <div className=" px-2 yellow-box-hover mb-4">
                <h5 className="text-center">Landmarks</h5>
                <ul className="my-3 text-start">
                  <li>HAL Heritage Museum- 3.1 km</li>
                  <li>Bellandur Junction - 5 Km</li>
                  <li>ITPL - 5.1 Km</li>
                  <li>HSR - 7.8 Km</li>
                  <li>Kempegowda International Airport - 45.6 Km</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="home-sec-10-poster" >
          <div className="poster-text">
            <h3 className="mb-0">EPIC Pass</h3>
          </div>
        </div>
      </div>
      <div className="home-sec-10" id="epic-pass-sec">
        <div className="content flex-wrap flex-md-nowrap col-11 col-xl-8">
          <div className="col-12 d-flex justify-content-center col-md-6">
            <img src={MobilePoster} alt="poster" />
          </div>
          <div className="col-12 col-md-6  mt-5">
            <h2 className="text-center">Limited Period Offer</h2>
            <h5 className=" mt-4 text-center">
              Get benifits of up to Rs. 5 Lakhs for the first 10 bookings.
            </h5>
            <div
              className="btn-yellow mx-auto mt-4"
              onClick={() => handleEpicModal()}
            >
              Get My Epic Pass
            </div>
            <h5 className=" mt-4 text-center">
              Get your Epic Pass today with the Unique Epic Pass Code to redeem this offer!
            </h5>
          </div>
        </div>
      </div>
      <div
        onScroll={handleScroll3}
        className="home-sec-11-parallax"
        id="parallax-3"
      >
        <div className="home-sec-11-poster">
          <div className="poster-text">
            <h3 className="mb-0">EPIC Musings</h3>
          </div>
        </div>
        <div className="col-12 py-5 mb-5 bg-col-white">
          <div className="home-sec-12 py-5  col-12">
            <Slider3 />
          </div>
        </div>
        <div className="home-sec-12-poster" id="faq-sec">
          <div className="poster-text">
            <h3 className="mb-0 text-center">Crafting Dreams into Reality</h3>
          </div>
        </div>
        <BuilderSection />
        <div className="home-sec-12-poster" id="faq-sec">
          <div className="poster-text">
            <h3 className="mb-0">FAQ's</h3>
          </div>
        </div>
      
        <div className="home-sec-12 bg-col-white col-12 ">
          <div className=" col-11 col-md-8 m-auto pb-5">
            <h3 className="my-5 text-center">
              Frequently Asked Questions: Navigating Your Queries with Ease
            </h3>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h6 className=" mb-0">What is the Codename EpicYield?</h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Codename EpicYield is a revolutionary residential apartment
                  for investment that challenges the norm by offering rental
                  yields far beyond the industry average. It redefines luxury
                  living and sustainable design while delivering exceptional
                  rental income of up to 10%.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    What makes Codename EpicYield different from other
                    residential projects?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Codename EpicYield stands out for its primary focus on
                  achieving extraordinary rental income.{" "}
                  <b>
                    While other projects may boast luxury amenities and prime
                    locations,{" "}
                  </b>{" "}
                  our project is designed and optimized to generate higher
                  returns on investment for property owners.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    How does Codename EpicYield achieve such high rental income?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Our project incorporates innovative design strategies,
                  advanced technology, and meticulous financial planning to
                  ensure optimal rental income. From unit layouts that maximize
                  space utilization to attracting high-demand tenant
                  demographics, every aspect is fine-tuned to boost rental
                  returns.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    What are the expected rental income for Codename EpicYield?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Codename EpicYield is designed to deliver rental income that
                  far surpass the industry norm. Investors can anticipate rental
                  yields of up to an impressive 10%, setting a new standard for
                  residential real estate returns.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    Is Codename EpicYield's rental yield sustainable in the long
                    term?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Yes, our project's rental yield is based on a carefully
                  crafted and viable long-term strategy. We take into account
                  market dynamics, demand trends for investment rental homes,
                  and potential rental appreciation to ensure that the yields
                  remain attractive for property owners over the years.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    Are there any hidden costs or fees that might impact rental
                    yields?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Transparency is one of our core principles. We ensure that all
                  costs and fees are clearly communicated to our investors.
                  There are no hidden expenses that would negatively impact your
                  rental income. Everything is laid out in a straightforward
                  manner.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    Is the Codename EpicYield located in a desirable area?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Absolutely! While our main focus is on exceptional rental
                  yields, we have not compromised on location. Codename
                  EpicYield is strategically situated in a prime area with huge
                  demand for such spaces, offering convenience, accessibility,
                  and all the benefits of a sought-after residential location.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    Can I invest in Codename EpicYield even if I am not looking
                    for rental income?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Certainly! While our project emphasizes rental income, it is
                  equally suitable for those looking for a luxurious and
                  sustainable place to call home. You can choose to live in the
                  property yourself while still benefiting from its high
                  potential rental yield if you decide to rent it out later.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    Is the Codename EpicYield environmentally friendly?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  Yes, sustainability is an integral part of our project. While
                  we prioritize rental income, we have not overlooked the
                  importance of eco-consciousness. Codename EpicYield is
                  designed with green building practices and eco-friendly
                  technologies to promote environmental responsibility.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <h6 className=" mb-0">
                    How can I invest in Codename EpicYield and secure high
                    rental income?
                  </h6>
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  To invest in Codename EpicYield and enjoy its exceptional
                  rental income, you can get in touch with our sales team. They
                  will guide you through the investment process, helping you
                  select the best-suited unit for your needs and financial
                  goals.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
      <FormModal modalShow={modalShow} setModalShow={setModalShow} />
      <EpicFormModal
        modalShow={epicModalShow}
        setModalShow={setEpicModalShow}
      />

    </>
  );
};

export default Home;
