import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css"
import { BiSolidQuoteAltRight } from "react-icons/bi"
const Slider2 = () => {
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "250px",
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   })
   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   });

   // we use the useEffect hook to listen to the window resize event
   useEffect(() => {
      window.onresize = () => {
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      };
   }, [window.innerWidth]);
   console.log(windowSize)

   useEffect(() => {
      if (windowSize.width <= 768) {
         setSettings(settings2)
      }
      else {
         setSettings(settings1)
      }
   }, [window.innerWidth])

   const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "250px",
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   return (
      <div className="col-12 musings">
         <Slider {...settings}>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltRight size={40} />
                  <h4 className="px-1 px-md-5 mt-4 text-center">
                     “Ninety percent of all millionaires become so through owning real estate. More money has been made in real estate than in all industrial investments combined.”<br />
                  </h4>
                  <p> - Andrew Carnegie</p>
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltRight size={40} />
                  <h4 className="px-1 px-md-5 mt-4 text-center">
                     “Every person who invests in well-selected real estate in a growing section of a prosperous community adopts the surest and safest method of becoming independent, for real estate is the basis of wealth.”<br />

                  </h4>
                  <p>- Theodore Roosevelt</p>
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltRight size={40} />
                  <h4 className="px-1 px-md-5 mt-4 text-center">
                     “Buying real estate is not only the best way, the quickest way, the safest way, but the only way to become wealthy.”<br />
                  </h4>
                  <p>- Marshall Field</p>
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltRight size={50} />
                  <h4 className="px-1 px-md-3 mt-4 text-center">
                     Real estate investing, even on a very small scale, remains a tried and true means of building an individual's cash flow and wealth."<br />
                  </h4>
                  <p>-Robert Kiyosaki</p>
               </div>
            </div>





         </Slider>
      </div>
   );
}

export default Slider2
