import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import Logo from "../Images/LogoNew.png"
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addEpicEnquiry",
        form
      );
      console.log("Enquiry submitted:", response.data);
      setForm({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      navigate('/confirmation-brouche')
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false)
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter modal-title">
          <img src={Logo} className="logo-img-modal" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>

          <div className="row ">
            <div className="col-12 my-3">
              <input
                className="input px-2"
                type="text"
                required
                placeholder="Name"
                value={form.name}
                onChange={handleFormChange}
                name="name"
              />
            </div>
            <div className="col-12">
              <input
                className="input px-2"
                type="email"
                required
                placeholder="Email"
                value={form.email}
                onChange={handleFormChange}
                name="email"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <input
                className="input px-2"
                type="text"
                required
                placeholder="Phone No."
                value={form.phone}
                onChange={handleFormChange}
                name="phone"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <input
                className="input px-2"
                type="text"
                placeholder="Enter a message.."
                value={form.message}
                onChange={handleFormChange}
                name="message"
              />
            </div>
          </div>
          <hr />
          <div
            className="d-flex justify-content-center modal-cont-ftr"
            type="submit"
          >
            <button disabled={loading} className="btn-yellow btn-black text-center modal-btn">
              {loading ? (<>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="mb-0" >&nbsp;&nbsp;Loading...</span></>) : ("Submit")}
            </button>
          </div>
        </Form>
      </Modal.Body>

    </Modal>
  );
}

const FormModal = ({ modalShow, setModalShow }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default FormModal;
