import React, { useState } from "react";
// import Logo from "../Images/Logo.svg";
import NewLogo from "../Images/LogoNew.png";
import "./style.css";
import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import { Offcanvas } from "react-bootstrap";
const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  return (
    <header className="header">
      <div className="logo-cont-header">
        <img src={NewLogo} className=" logo-img-header" alt="logo" />
      </div>
      <div className=" d-none d-lg-flex">
        <ul className="header-routes">
          <a href="#overview-sec">
            <li>Overview</li>
          </a>
          <a href="#epic-amenities-sec">
            <li>Amenities</li>
          </a>
          <a href="#highlights-sec">
            <li>Highlights</li>
          </a>
          <a href="#floor-plans-sec">
            <li>Floor Plans</li>
          </a>
          <a href="#gallery-sec">
            <li>Gallery</li>
          </a>
          <a href="#location-sec">
            <li>Location</li>
          </a>
          <a href="#epic-pass-sec">
            <li>Epic Pass</li>
          </a>
          <a href="#about">
            <li>About</li>
          </a>
          <a href="#faq-sec">
            <li>FAQ's</li>
          </a>
          {/* <li>
            <AiOutlineSearch />
          </li> */}
        </ul>
      </div>
      <div className="col-2 d-flex justify-content-end pe-3 d-lg-none">
        <div onClick={() => toggleShow()} type="submit">
          <AiOutlineMenu />
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        scroll={true}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul onClick={()=>{setShow(false)}} className="header-routes-canvas text-start d-flex flex-column">
            <a href="#overview-sec">
              <li>Overview</li>
            </a>
            <a href="#epic-amenities-sec">
              <li>Amenities</li>
            </a>
            <a href="#highlights-sec">
              <li>Highlights</li>
            </a>
            <a href="#floor-plans-sec">
              <li>Floor Plans</li>
            </a>
            <a href="#gallery-sec">
              <li>Gallery</li>
            </a>
            <a href="#location-sec">
              <li>Location</li>
            </a>
            <a href="#epic-pass-sec">
              <li>Epic Pass</li>
            </a>
            <a href="#about">
            <li>About</li>
          </a>
            <a href="#faq-sec">
              <li>FAQ's</li>
            </a>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
