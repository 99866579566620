import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import poster1 from "../../Images/Gallery/1.webp";
import poster2 from "../../Images/Gallery/2.webp";
import poster3 from "../../Images/Gallery/3.webp";
import poster4 from "../../Images/Gallery/4.webp";
import poster5 from "../../Images/Gallery/5.webp";
import "./slider.css";
const Slider2 = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const posters = [poster1, poster2, poster3, poster4, poster5];
  // we use the useEffect hook to listen to the window resize event
  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }, [window.innerWidth]);
  console.log(windowSize);

  useEffect(() => {
    if (windowSize.width <= 768) {
      setSettings(settings2);
    } else {
      setSettings(settings1);
    }
  }, [window.innerWidth]);
  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "250px",
    arrows: true,
    autoplaySpeed: 3000,
  });
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "250px",
    arrows: true,
    autoplaySpeed: 3000,
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="col-12 design">
      <Slider {...settings}>
        {posters.map((data) => (
          <div className="card-box-1">
            <div className="card-box-img ">
              <img className="w-100" src={data} alt="photo" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Slider2;
