import React, { useState } from 'react'
import Slider from 'react-slick'
import img1 from "../Images/Section13/Delivery Time.png"
import img2 from "../Images/Section13/Facebook Like.png"
import img3 from "../Images/Section13/Guarantee.png"
import img4 from "../Images/Section13/Handshake.png"
import poster1 from "../Images/Section13/1.png"
import poster2 from "../Images/Section13/2.png"
import poster3 from "../Images/Section13/3.png"
import poster4 from "../Images/Section13/4.png"
const BuilderSection = () => {
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "0px",
      autoplay: true,
      arrows: false,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   })
   const posters = [poster1, poster2, poster3, poster4];
   return (
      <div id="about" className='section-13 py-5'>
        
         <div className="col-12 d-flex flex-wrap flex-row align-items-center">

            <div className="col-12 d-flex justify-content-center col-md-6 px-3 px-md-2 px-lg-4 px-xl-5">
               <div className="col-12 col-md-10 col-xl-9 pb-5 musings">
                  <Slider {...settings}>
                     {posters.map((data) => (
                        <div className="card-box-1">
                           <div className="card-box-img ">
                              <img src={data} alt="photo" />
                           </div>
                        </div>
                     ))}
                  </Slider>
               </div>

            </div>
            <div className="col-12 col-md-6 px-3 px-md-2 px-lg-4 px-xl-5 mt-4 mt-md-0">
               <div className='d-flex justify-content-between mb-4'>
                  <div className="box">
                     <img className="mb-2" style={{ width: "35px" }} src={img4} alt="logo" />
                     <h6 className='text-center'><span>Upcoming Projects</span></h6>
                     <p>Explore our forward-looking vision for residential excellence embodied in three exciting upcoming projects. </p></div>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img2} alt="logo" /><h6 className='text-center'><span>Expert team</span></h6>
                     <p>Our seasoned professionals bring vast knowledge and experience to every project, ensuring your dream home is crafted with precision and care. </p></div>
               </div>
               <div className='d-flex justify-content-between'>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img1} alt="logo" /><h6 className='text-center'><span>Luxury & Exclusive Craftsmanship</span></h6>
                     <p>Witness our dedication to exclusive craftsmanship in every project, from carefully selected materials to the finest finishing touches.</p></div>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img3} alt="logo" /><h6 className='text-center'><span>Green Living</span></h6>
                     <p>Our projects integrate eco-conscious design, green technologies, and thoughtful landscaping for a planet-friendly lifestyle.</p></div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default BuilderSection