import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import ConfirmationPage from './Components/Thankyou';
import TagManager from 'react-gtm-module';
import PrivacyPolicy from './Components/PrivacyPolicy';
function App() {
// const tagManagerArgs = {
//   gtmId:'GTM-N26X9JH5'
// }
// TagManager.initialize(tagManagerArgs)
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/confirmation-brouche" exact element={<ConfirmationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
