import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import { BiSolidBusiness, BiSolidCarGarage, BiSpa } from "react-icons/bi";
import { SiClubhouse } from "react-icons/si";
import {
  GiEightBall,
  GiGroundbreaker,
  GiMeditation,
  GiSparkles,
} from "react-icons/gi";
import { CgGym } from "react-icons/cg";
import { LiaSwimmingPoolSolid, LiaTableTennisSolid } from "react-icons/lia";
import { RiBilliardsLine } from "react-icons/ri";
import { LuSchool2, LuTrees, LuTreeDeciduous } from "react-icons/lu";
import { PiHouseBold } from "react-icons/pi";
const Slider1 = () => {
  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25px",
    autoplay: true,
    autoplaySpeed: 3000,
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // we use the useEffect hook to listen to the window resize event
  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (windowSize.width <= 450) {
      setSettings(settings3);
    } else if (windowSize.width <= 768) {
      setSettings(settings2);
    } else {
      setSettings(settings1);
    }
  }, [window.innerWidth]);

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25px",
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25px",
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const settings3 = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25px",
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="col-12 amenities">
      <Slider {...settings}>
        <div className="card-box-1">
          <div className="card-box">
            <BiSolidBusiness />
            <h5>Business Center</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <PiHouseBold />
            <h5>Club House</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <CgGym />
            <h5>Gym</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <LuTrees />
            <h5>Landscaped Garden</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <BiSolidCarGarage />
            <h5>Covered Car Parking</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <RiBilliardsLine />
            <h5>Billiards</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <LuSchool2 />
            <h5>Day Care</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <GiMeditation />
            <h5>Meditation Hall</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <GiGroundbreaker />
            <h5>Play Area</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <GiEightBall />
            <h5>Personnel Snooker</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <BiSpa />
            <h5>Spa</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <LiaSwimmingPoolSolid />
            <h5>Swimming Pool</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <LiaTableTennisSolid />
            <h5>Table Tennis</h5>
          </div>
        </div>
        <div className="card-box-1">
          <div className="card-box">
            <LuTreeDeciduous />
            <h5>Terrace Garden</h5>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Slider1;
