import React, { useState } from "react";
import Logo from "../Images/EPIC YIELD LOGO.png";
import aspireLogo from "../Images/aspireLogo.png";
import hirenWahu from "../Images/medium2.png";
import { Link, useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import axios from "axios";

const Footer = () => {
  const navigate = useNavigate()
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });
  const [loading,setLoading] = useState(false)

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addEpicEnquiry",
        form
      );
      console.log("Enquiry submitted:", response.data);
      setForm({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      navigate('/confirmation-brouche')
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false)
  };
  return (
    <>
      <div className="home-sec-13 flex-lg-nowrap alig-items-start flex-wrap px-0 py-3 px-md-5 px-lg-3">
        <div className="col-12 col-lg-4 col-md-6 px-5">
          <div className="d-flex justify-content-evenly align-items-center">
            <img style={{ height: "50px", width: "50px" }} src={hirenWahu} alt="logo" className="col-6 logo-img2" />
            <img style={{ height: "70px" }} src={Logo} alt="logo" className="logo-img1 col-6" />
          </div>
          <div className="row mb-3">
            <div className="col-3">
              <p className="bold">Address</p>
            </div>
            <div className="col-9">
              Panathur Main Road (100 Mtrs Off ORR), Marathahalli, Bengaluru, Karnataka - 560 103
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3">
              <p className="bold">Phone</p>
            </div>
            <div className="col-9 d-flex flex-wrap"><div>+91 98866 60259&nbsp;|&nbsp; </div><div>+91 95133 19002 </div></div>
          </div>

          <div className="row">
            <div className="col-3">
              <p className="bold">Rera Number</p>
            </div>
            <div className="col-9 footer-rera">
              PRM/KA/RERA/1251/309/PR/181109/002131
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-6 px-5">
          <div className="d-flex justify-content-center">
            <img style={{ height: "70px", width: "70px" }} src={aspireLogo} alt="logo" />
          </div>
          <div className="row mt-1 mb-3">
            <div className="col-3">
              <p className="bold">Address</p>
            </div>
            <div className="col-9">
              45/11, First Floor, North Park Road, Kumara Park East, Bengaluru
              560001
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3">
              <p className="bold">Phone</p>
            </div>
            <div className="col-9 d-flex flex-wrap"><div>+91 98866 60259&nbsp;|&nbsp;</div><div>+91 95133 19002</div></div>
          </div>
          {/* <div className="row mb-3">
            <div className="col-3">
              <p>Email</p>
            </div>
            <div className="col-9 ">hello@aspireprop.com</div>
          </div> */}
          <div className="row">
            <div className="col-3">
              <p className="bold">Website</p>
            </div>
            <div className="col-9 ">www.aspireprop.com</div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-12 mt-md-5 px-5">
          <div>
            <h5>Enquiry Form</h5>
          </div>
          <Form onSubmit={handleFormSubmit}>

            <div className="row ">
              <div className="col-6">
                <input className="input px-2" type="text"
                  required
                  placeholder="Name"
                  value={form.name}
                  onChange={handleFormChange}
                  name="name" />
              </div>
              <div className="col-6">
                <input
                  className="input px-2"
                  type="email"
                  required
                  placeholder="Email"
                  value={form.email}
                  onChange={handleFormChange}
                  name="email"
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <input className="input px-2" type="text"
                  required
                  placeholder="Phone No."
                  value={form.phone}
                  onChange={handleFormChange}
                  name="phone" />
              </div>
            </div>

            <div className="row my-3">
              <div className="col-12">
                <input
                  className="input px-2 "
                  type="text"
                  value={form.message}
                  onChange={handleFormChange}
                  name="message"
                  placeholder="How can i help you today?"
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-end modal-cont-ftr"
              type="submit"
            >
              <button disabled={loading} className="btn-yellow btn-black text-center modal-btn">
                {loading ? (<>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="mb-0" >&nbsp;&nbsp;Loading...</span></>) : ("Submit")}
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div className="home-sec-14 p-4  ">
        <p className="mt-3 text-center">
          <b>Disclaimer</b>
        </p>
        <p className="mt-2 text-center text-size-sm">
          The content mentioned in this website is for
          information purposes only, prices mentioned are subject to change
          without notice & properties mentioned are subject to availability.
          Images are for representation purposes only. This is not the official
          website. This website belongs to the official channel partner of Hiren
          Wahen Group. We may also send updates to the mobile email id
          registered with us. All Rights Reserved.
        </p>
        <p>

          <Link style={{
            display: "block",
            textDecoration: "none",
            color: "black"
          }} to={'/privacy-policy'} className="mt-3 text-center">
            <b>Privacy Policy</b>
          </Link>
        </p>
        {/* <p className="mt-2 text-center text-size-sm">
          This policy is in relation to the information/data we collect from you
          when you use our website and the manner in which we hold, process and
          use the data collected.
        </p> */}
      </div>
    </>
  );
};

export default Footer;
