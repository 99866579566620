import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRef, useState } from "react";
import Logo from "../Images/LogoNew.png"
import axios from "axios";
import EpicPassImg from "../Images/EpicPassImg.png"
import { Form, Spinner } from "react-bootstrap";
import "./pass.css"
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { BiHide } from "react-icons/bi";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
  });
  const [passModalShow,setPassModalShow] = useState(false)
  const navigate = useNavigate()
  const [passNo, setPassNo] = useState("")
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      axios
        .post(
          "https://api.aspireprop.com/cn/epicpass",
          form)
        .then((response) => {
          setPassNo(response?.data?.data)
          console.log("Enquiry submitted:", response.data);
        });
      props.setModalShow(false)
      setPassModalShow(true)
    } catch (err) {
      console.log(err, "an error occured!");
    }


    setLoading(false)
  };

  const handleDownload = () => {
    // navigate(`/projects`);
    setLoading(true)
    var nodePass = document.getElementById("pass");
    html2canvas(nodePass).then(async function (canvas) {
      var img = canvas.toDataURL(`image/png`);
      var link = document.createElement("a");
      link.download = "pass." + "png";
      link.href = img;
      var file = dataURLtoFile(link.href, "pass.png");
      console.log(file)
      const formdata = new FormData();
      formdata.append("image", file);
      formdata.append("name", form.name)
      formdata.append("email", form.email)
      formdata.append("phone", form.phone)
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post("https://api.aspireprop.com/cn/emailepicpass", formdata, config)
        .then((response) => {
          console.log(response);
        });
      link.click();
    });
    navigate('/confirmation-brouche')
    setLoading(false)
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  console.log(form);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter modal-title">
            <img src={Logo} className="logo-img-modal" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            {/* <div className="d-flex justify-content-center">

              <div id="pass" className="row pass-img">
                <img src={EpicPassImg} alt="pass" />
                <div className="code">{passNo}</div>
              </div>
            </div> */}

            <div className="row ">
              <div className="col-12 my-3">
                <input
                  className="input px-2"
                  type="text"
                  required
                  placeholder="Name"
                  value={form.name}
                  onChange={handleFormChange}
                  name="name"
                />
              </div>
              <div className="col-12">
                <input
                  className="input px-2"
                  type="email"
                  required
                  placeholder="Email"
                  value={form.email}
                  onChange={handleFormChange}
                  name="email"
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <input
                  className="input px-2"
                  type="text"
                  required
                  placeholder="Phone No."
                  value={form.phone}
                  onChange={handleFormChange}
                  name="phone"
                />
              </div>
            </div>

            <hr />
            <div
              className="d-flex justify-content-center modal-cont-ftr"
              type="submit"
            >
              <button disabled={loading} className="btn-yellow btn-black text-center modal-btn">
                {loading ? (<>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="mb-0" >&nbsp;&nbsp;Loading...</span></>) : ("Submit")}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

       <Modal
        show={passModalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setPassModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter modal-title">
            <img src={Logo} className="logo-img-modal" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleDownload}>
            <div className="d-flex justify-content-center">

              <div id="pass" className="row pass-img">
                <img src={EpicPassImg} alt="pass" />
                <div className="code">{passNo}</div>
              </div>
            </div>
            <hr />
            <div
              className="d-flex justify-content-center modal-cont-ftr"
              type="submit"
            >
              <button disabled={loading} className="btn-yellow btn-black text-center modal-btn">
                {loading ? (<>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="mb-0" >&nbsp;&nbsp;Loading...</span></>) : ("Continue...")}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const EpicFormModal = ({ modalShow, setModalShow }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        backdrop="static"
        setModalShow={setModalShow}
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default EpicFormModal;
