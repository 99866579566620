import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import Image1 from "../../Images/Banner1New.png";
import Image2 from "../../Images/Banner2New.png";
import Image3 from "../../Images/MobileView1.png";
import Image4 from "../../Images/MobileView2.png";
const SliderHome = () => {
  const [settings, setSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //   const windowWidth = window.innerWidth;
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 2);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 2) % 2);
  };

  return (
    <div className="col-12 home">
      <Slider {...settings}>
        {/* {currentSlide === 0 ? ( */}
        <div className="home-img">
          {windowWidth >= 768 ? (
            <img src={Image1} alt="poster" className=" home-window-img" />
          ) : (
            <img src={Image3} alt="poster" className=" home-mobile-img" />
          )}
        </div>
        {/* ) : ( */}
        <div className="home-img">
          {windowWidth >= 768 ? (
            <img src={Image2} alt="poster" className=" home-window-img" />
          ) : (
            <img src={Image4} alt="poster" className=" home-mobile-img" />
          )}
        </div>
        {/* )} */}
      </Slider>
      {/* <div>
        <button onClick={prevSlide}>Previous</button>
        <button onClick={nextSlide}>Next</button>
      </div> */}
    </div>
  );
};

export default SliderHome;
