import React from "react";
import msg from "../Images/message.png";
export default function ConfirmationPage() {
   return (
      <div className="confirm-page-container">
         <h2 className="text-center">THANK YOU FOR CONTACTING US!</h2>
         <img alt="msg-img" src={msg} className="confirm-msg" />
         <p className="text-center">
            Hey there, you are now a step closer to unlocking #EpicYields.
            <br />
            Our super squad will speak to you soon.
         </p>
         <a href={require("../Components/Pdf/Brochure-EpicYield.pdf")} target="_blank" className="confirm-btn" download="Brochure-EpicYield">Download Brochure</a>
      </div>
   );
}